import React, { Component } from 'react'
import { graphql, StaticQuery} from 'gatsby'
import styled from 'styled-components'

const Feed = styled.ul`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    width: 100%;
    padding: 0%;
    margin: 0%;
    align-self: center;
    padding-bottom: 7%;   
`
const Post = styled.li`
    margin-top: 2%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    width: 20%;
    border-radius: 2%;
    list-style: none;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    :hover{
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
    display: inline-block;
    ::-moz-focus-inner {border:0;}
    :focus{outline: none;}
`
const Image = styled.img`
    max-height: 100%;
    max-width: 100%;
    object-fit: fill;
    display: inline;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
`
const Date= styled.p`
    width: 100%;
    font-size: 100%;
    font-family: 'PT Sans', sans-serif;
`
const Title=styled.h2`
    font-size: 150%;
    width: 100%;
    font-family: 'Raleway';
    font-weight: bold;
    color: #1B4965;
`
const ImageSection=styled.div`
    width: 100%;
`
const TextSection=styled.div`
    width: 94%;
    margin-right: 3%;
    margin-left: 3%;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex; 
    flex-direction: column;
    height: 20%;
    text-align: center;
`
const DateSection=styled.div`
    width: 100%;
    margin-top: 5%;
    display: flex; 
    flex-direction: column;
    justify-content: flex-end;
    height: 5%;
    text-align: center;
`
const PostLink = styled.a`
    display: flex;
    flex-direction: column;
    color: black;
    text-decoration:none;
    ::-moz-focus-inner {border:0;}
    :focus{outline: none;}
    height: 100%;
    width: 100%;
    background-color: white;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
`
class BlogFeed extends Component{
    state={}
    render(){
        return(
            <StaticQuery query={graphql`
                query MyQuery {
                    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, limit: 1000) {
                        edges {
                            node {
                                frontmatter {
                                    title
                                    date(formatString: "MMMM DD, YYYY")
                                    path
                                    thumbnail
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <Feed>
                    <style>@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');</style> 
                {data.allMarkdownRemark.edges.map(x =>(
                    <Post>
                        <PostLink href={x.node.frontmatter.path}>
                            <ImageSection>
                                <Image src={"/"+x.node.frontmatter.thumbnail}/>
                            </ImageSection>
                            <TextSection>
                                <Title>{x.node.frontmatter.title}</Title>
                            </TextSection>
                            <DateSection>
                                <Date>{x.node.frontmatter.date}</Date>
                            </DateSection>
                        </PostLink>
                    </Post>

                ))}
                </Feed>
            )}
            />
        )
    }
}
export default BlogFeed
