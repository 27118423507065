import React, { Component } from "react"
import BlogLayout from '../components/BlogLayout'
import BlogFeed from '../components/BlogFeed'

class Blog extends Component{
    render(){
        return(
            <BlogLayout>
                <BlogFeed />
            </BlogLayout>
        )
    }
}

export default Blog